import { MouseEvent, ReactElement, useRef } from 'react';
import Tooltip, { TooltipClasses } from '@mui/material/Tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { Instance } from '@popperjs/core';
import { PopperProps } from '@mui/material';

export default function SrTooltip({
  title,
  placement = 'top',
  children,
  classNameForTooltip = '',
  popperProps,
  onClick,
  classes,
  classNameForPopperProps = '',
  style,
  additionalActionOnMouseOver,
  additionalActionOnMouseLeave,
  conditionalIndentForTooltipToNotOverlapPositionX = false,
  conditionalIndentForTooltipToNotOverlapPositionY = false,
  additionalIndentForTooltipToTop = 0,
  active = true,
  open = undefined,
  x,
  y,
  applyZoom,
  relativePlacement,
}: {
  title: string | ReactElement;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  children?: ReactElement;
  classNameForTooltip?: string;
  popperProps?: Partial<PopperProps>;
  classes?: Partial<TooltipClasses>;
  onClick?: () => void;
  classNameForPopperProps?: string;
  style?: any;
  additionalActionOnMouseOver?: () => void;
  additionalActionOnMouseLeave?: () => void;
  conditionalIndentForTooltipToNotOverlapPositionX?: boolean;
  conditionalIndentForTooltipToNotOverlapPositionY?: boolean;
  additionalIndentForTooltipToTop?: number;
  active?: boolean;
  open?: boolean | undefined;
  x?: number;
  y?: number;
  applyZoom?: boolean;
  relativePlacement?: boolean;
}): ReactElement {
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);
  const userAgent = navigator.userAgent.toUpperCase();
  const isChrome =
    userAgent.includes('CHROME') && !userAgent.includes('OPR') && !userAgent.includes('EDGE');

  if (!active) return children as ReactElement;

  const handleMouseOverAndFocus = (event: MouseEvent): void => {
    if (additionalActionOnMouseOver) additionalActionOnMouseOver();
    positionRef.current = {
      x: conditionalIndentForTooltipToNotOverlapPositionX && x ? x : event.clientX,
      y:
        conditionalIndentForTooltipToNotOverlapPositionY && y
          ? y - additionalIndentForTooltipToTop
          : event.clientY - additionalIndentForTooltipToTop,
    };

    if (popperRef.current !== null) {
      popperRef.current.update();
    }
  };

  const handleMouseLeave = (): void => {
    if (additionalActionOnMouseLeave) additionalActionOnMouseLeave();
  };

  return (
    <Tooltip
      open={open}
      title={title}
      placement={placement}
      arrow
      classes={classes}
      PopperProps={{
        ...popperProps,
        onClick,
        className: classNameForPopperProps,
        popperRef,
        ...(!relativePlacement && {
          anchorEl: {
            getBoundingClientRect: () => {
              const zoomScale = +document.documentElement.style.getPropertyValue('--zoom-scale');

              return new DOMRect(
                isChrome && applyZoom
                  ? positionRef.current.x / zoomScale || (x && x / zoomScale)
                  : positionRef.current.x || x,
                isChrome && applyZoom
                  ? positionRef.current.y / zoomScale || (y && y / zoomScale)
                  : positionRef.current.y || y,
                0,
                0
              );
            },
          },
        }),
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        className={classNameForTooltip}
        style={style}
        onMouseOver={handleMouseOverAndFocus}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
    </Tooltip>
  );
}
